<template>
  <div class="nav">
    <div id="navTab">
      <a href="/" class="logo">
        <img src="@/assets/logo/pubrio-logo.png" />
        <!-- <BetaLogo /> -->
      </a>
      <top-nav style="flex: 10" />
    </div>
    <div id="smallNav">
      <el-button @click="drawer = true">
        <svg-icon icon-class="list" />
      </el-button>

      <div class="active-catalog">
        {{ catalog }}
      </div>
    </div>

    <el-drawer :append-to-body="true" :visible.sync="drawer" direction="ltr">
      <span slot="title">
        <a href="/" class="logo-1">
          <img src="@/assets/logo/pubrio-logo.png" />
          <BetaLogo />
        </a>
      </span>
      <div class="list-menu" @click="closeDrawer">
        <div
          v-for="item in filterList"
          :key="item.name"
          :class="[
            'list-menu-item',
            item.children ? 'has-children' : 'no-children',
          ]"
        >
          <span class="title" @click="navToPage(item.url)">
            <svg-icon :icon-class="item.icon" />
            {{ $t(item.name) }}
          </span>
          <div v-if="item.desc" class="desc">{{ item.desc }}</div>
          <div v-if="item.children">
            <div
              v-for="child in item.children"
              :key="child.name"
              class="sub-item"
              @click="navToPage(child.url)"
            >
              <div class="sub-title">
                <svg-icon :icon-class="child.icon" :class="child.icon"/>
                {{ $t(child.name) }}
              </div>
              <div class="sub-desc">{{ child.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <div class="blank"></div>
    <top-right style="flex: 3" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import topNav from "@/components/topNav";
import topRight from "@/components/top-right";
import BetaLogo from '@/components/BetaLogo.vue';

export default {
  name: "navBar",
  components: {
    topNav,
    topRight,
    BetaLogo
  },
  data() {
    return {
      drawer: false,
      screenWidth: null, //屏幕宽度
      enableSearch: false,
      enableIntent: false,
      enableTool: false,
      enableService: false,
      enableSequence: false,
      list: [
        {
          name: "Home",
          dataKey: "home",
          icon: "home",
          url:''
        },
        {
          name: "Lead",
          dataKey: 'search',
          icon: "nav-lead",
          children: [
            {
              name: "Prospects",
              icon: "user",
              desc: `${this.$t('filterYourSearchToFindHighlySpecific')}${this.$t('groupsOfTargetedLeads')}`,
              url: '/search/people',
            },
            {
              name: "companies",
              icon: "filter-companies",
              desc: `${this.$t('filterYourSearchToFindPreciselyTargeted')}${this.$t('listsOfCompanies')}`,
              url: '/search/company',
            },
            {
              name: "Enrich",
              icon: "nav-enrich",
              desc: "Instantly enhance your data by adding valuable insights and key details from various sources",
              url: "/search/enrich"
            },
          ],
        },
        {
          name: "Intent",
          dataKey: 'intent_signals',
          icon: "cast",
          children: [
            {
              name: "Website_Intent",
              icon: "Api",
              desc: `${this.$t('transformAnonymousWebsiteTrafficInto')}${this.$t('b2bLeadsWithRealtimeIdentification')}`,
              url: '/intent-signals/intent',
            },
            {
              name: "Market_Intent",
              icon: "Crm",
              desc: `${this.$t('discoverAndConnectWithPotentialBuyers')}${this.$t('activelySeekingYourSolutionToday')}`,
              url: "/intent-signals/market",
            },
          ],
        },
        {
          name: "Sequence",
          dataKey: 'sequences',
          icon: "Sent",
          desc: "Automate and personalize your outreach to save time and engage your prospects",
          url: "/sequence/sequence-list",
        },
        {
          name: "Services",
          dataKey: 'services',
          icon: "server",
          desc: "Comprehensive business solutions and support services for your needs",
          url: "/services/overview",
        },
      ],
    };
  },
  computed: {
    ...mapState('user',['systemConfig']),
    catalog() {
      return this.$route.meta.title !== "companyDetail"
        ? this.$route.meta.title
        : "Company";
    },
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        if (val > 767) {
          this.drawer = false;
        }
      },
      immediate: true,
    },
    systemConfig: {
      handler(val) {
        console.log('tttt');
        console.log(val.disabled_navigation_display)
        if (val && val.disabled_navigation_display) {
          const navigationDisplay = this.systemConfig.disabled_navigation_display;
          this.filterList = this.list.filter((item) => !navigationDisplay.includes(item.dataKey))
        }
      },
      immediate:true,
    },
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      this.screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    };
  },
  methods: {
    navToPage(url) {
      url && this.$router.push(url);
    },
    closeDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped lang="scss">
.nav {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 51px;
  border-bottom: 1px solid $color-gray-0;
  background-color: #fff;
  z-index: 100;
  @media (max-width: 991px) {
    .el-menu-item {
      padding: 0;
    }
    .el-drawer.ltr {
      display: none !important;
    }
    .logo {
      img:first-child {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    #navTab {
      display: none !important;
    }
    #smallNav {
      display: flex !important;
    }
  }
  #navTab {
    position: relative;
    flex: 6;
    display: flex;
    align-items: center;
    height: 100%;
    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 24px;
      img {
        width: 116.84px;
        height: 30px;
        margin-right: 8px;
      }
    }
  }
  .blank {
    flex: 6;
    height: 50px;
  }
  #smallNav {
    display: none;
    align-items: center;
    font-weight: 600;
    .el-button {
      border: none;
      padding: 0 10px;
      background-color: transparent;
    }
    .svg-icon {
      margin: 0 8px;
      font-size: 25px;
      line-height: 25px;
      color: $color-blue-4 !important;
    }
    .active-catalog {
      font-size: 16px;
      color: $color-blue-4;
    }
  }
}

::v-deep .el-drawer.ltr {
  width: 60% !important;
  height: 100%;
  .logo-1 {
    display: flex;
    align-items: center;
    width: 124.78px;
    img {
      width: 100%;
    }
  }

  .el-drawer__header {
    padding: 13px 16px;
    border-bottom: 1px solid $color-gray-0;
    margin-bottom: 0;
  }
  .el-drawer__body {
    .list-menu {
      display: flex;
      flex-direction: column;
      padding: 16px 8px;
      &-item {
        padding: 8px;
        border-radius: 8px;
        &.no-children {
          margin-bottom: 16px;
          cursor: pointer;
          .desc {
            margin-top: 8px;
          }
          &:hover {
            background-color: $color-blue-1;
          }
        }
        &:first-child {
          margin-bottom: 0;
        }
        .title {
          display: flex;
          align-items: center;
          color: $color-blue-4;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.45px;
          .svg-icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
        .desc,
        .sub-desc {
          color: $color-black-3;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
        }
        .sub-desc {
          margin-top: 8px;
        }
        .sub-item {
          margin-top: 8px;
          padding: 8px;
          border-radius: 8px;
          cursor: pointer;
          &:first-child {
            margin-top: 16px;
          }
          &:hover {
            background-color: $color-blue-1;
          }
          .sub-title {
            color: $color-black-2;
            font-size: 13px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.39px;
            .svg-icon {
              width: 14px;
              height: 14px;
              margin-right: 8px;
            }
            .cast {
              height: 12px;
            }
          }
        }
      }
    }
  }
  .el-drawer__close-btn {
    color: $color-blue-2;
  }
}
</style>



